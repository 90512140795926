import { useState, useEffect, useCallback, useMemo } from 'react';
import logo from './logo.svg';
import './App.css';

const DEFAULT_TIMER = 120;

function Button({ value, callback, keyp, className, ...props }) {
  const [clicked, setClicked] = useState(0);

  const states = useMemo(() => ([
      clicked ? 'active' : null,
      'button',
      className,
    ].filter(Boolean).join(' '))
  , [className, clicked]);

  const click = useCallback(() => {
    const callbackValue = !clicked ? value : 0;
    setClicked(clicked => !clicked);
    callback(value, callbackValue);
  }, [clicked]);

  const keyHandler = useCallback(({ key }) => {
      if (key == keyp)
        click();
  }, [click]);

  useEffect(() => {
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  }, [keyHandler]);

  return <div onClick={click} className={states} {...props}>{value}</div>
}

function makeTarget(prevTarget) {
  const min = Math.min(99, prevTarget);
  const max = Math.min(254, prevTarget * 2);
  const nextTarget = Math.floor(Math.random()*(max - min) + min);
  if (nextTarget === prevTarget)
    return makeTarget(prevTarget);
  return nextTarget;
}

function Play({ timer: _timer, done }) {
  const [states, setStates] = useState({});
  const [sum, setSum] = useState(0);
  const [score, setScore] = useState(0);
  const [target, setTarget] = useState(makeTarget(5));
  const [timer, setTimer] = useState();
  const [intervalID, setIntervalID] = useState();
  const [hint, setHint] = useState();
  const [showHint, setShowHint] = useState(false);

  const isPractice = useMemo(() => _timer === -1, [_timer]);

  useEffect(() => {
    setHint(_ => showHint ? `Σ ${sum}`: 'HINT');
  }, [showHint, sum]);

  const step = useCallback(() => {
    setTimer(timer => {
      const nextTimer = timer - 1
      if (nextTimer < 0) {
        setScore(score => done(score))
      }
      return nextTimer;
    });
  }, []);

  useEffect(() => {
    if (_timer && _timer > 0) {
      setTimer(_timer);
      setIntervalID(setInterval(step, 1000));
    }
    return () => clearInterval(intervalID);
  }, [_timer]);

  const change = useCallback((grp, value) => {
    setStates(prevStates => {
      return { ...prevStates, [grp]: value };
    })
  }, []);

  useEffect(() => {
    setSum(Object.values(states).reduce((sum, c) => sum+c, 0));
  }, [states]);

  const [t, setT] = useState(0);
  useEffect(() => {
    if (sum === target) {
      setTarget(prev => makeTarget(prev));
      setScore(score => score + 1);
      setT(t => (t+1)%2)
    }
  }, [sum, target]);

  return (
    <div className="App">
      <div className="header">
        <div className="left">
          <Button className="longbtn reload" callback={() => window.location.reload()} value="RST" />
        </div>
        <div className="center">
          Score<div className={`t${t}`}>{score}</div>
        </div>
        <div className="right">
          {timer || timer === 0 ? <><div className="timer">Time</div><div>{timer}"</div></> : null }
          { isPractice ? <Button className="hint longbtn" callback={() => setShowHint(hint => !hint)} value={hint} /> : null }
        </div>
      </div>

      <div className="body">
        <div className={`target t${t}`}>{target}</div>
      </div>

      <div className="footer">
        <div className="container">
          <Button callback={change} keyp="1" value={128} />
          <Button callback={change} keyp="2" value={64} />
          <Button callback={change} keyp="3" value={32} />
          <Button callback={change} keyp="4" value={16} />
          <Button callback={change} keyp="7" value={8} />
          <Button callback={change} keyp="8" value={4} />
          <Button callback={change} keyp="9" value={2} />
          <Button callback={change} keyp="0" value={1} />
        </div>
      </div>
    </div>
  );
}

function loadHighScore() {
  const hs = localStorage.getItem('hs');
  return hs ? Number(hs) : undefined;
}

function saveHighScore(hs) {
  let prevScore = localStorage.getItem('hs');
  if (prevScore) {
    prevScore = Number(prevScore);
    if (prevScore < hs) {
      localStorage.setItem('hs', String(hs));
    }
  } else {
    localStorage.setItem('hs', String(hs));
  }
}

function App() {
  const [timer, setTimer] = useState();
  const [lastScore, setLastScore] = useState();
  const [highScore, setHighScore] = useState();

  const play = () => setTimer(DEFAULT_TIMER);

  const practice = () => setTimer(-1);

  useEffect(() => {
    setHighScore(loadHighScore());
  }, []);

  const done = useCallback((score) => {
    setTimer();
    if (score === undefined)
      return;
    setLastScore(score);
    if (!highScore || score > highScore) {
      setHighScore(score);
      saveHighScore(score);
    }
  }, [highScore]);

  console.log("render", lastScore, highScore);

  return timer ? <Play timer={timer} done={done} /> :
    (timer === -1 ? <Play /> :
    <div className="App" style={{justifyContent: "space-around"}}>
        <div className="vflex">
            <div>Binary Game</div>
        </div>
      <div className="vflex">
        { lastScore !== undefined ? <div style={{marginBottom: '2rem'}}>Game Over! Score: {lastScore}</div> : null }
        <Button callback={play} value="Play" style={{ width: '200px', marginBottom: '5px' }}/>
        <Button callback={practice} value="Practice" style={{ width: '200px' }}/>
      </div>
        <div className="">
          <div className="center">
            { highScore !== undefined ? <div style={{marginTop: '2rem'}}>High Score: {highScore}</div> : null }
          </div>
        </div>
    </div>)
}

export default App;
